<template>
    <v-row justify="center">
        <v-dialog
            v-model="showModal"
            v-click-outside="{
                handler : onClose,
                closeConditional : onOpenModal
            }"
            max-width="600"
            min-height="500"
        >
        <div>
            <v-card  class="text-center">
            
                <v-icon class="mt-5" size="120px" color="#FFA41A">
                    mdi-alert-outline
                </v-icon>
                <v-col >
                </v-col>
                <v-col >
                    <span style="white-space: pre-line;font-size: 36px; font-weight: bolder;">{{textTitle}}</span>
                </v-col>
                <v-col>
                    <span style="white-space: pre-line;font-size: 20px; font-weight: 500; color: #918F8F;">{{textDetail}} </span>    
                </v-col>
                <v-col>
                    <v-btn
                        width="140"
                        class="white--text"
                        color="#D55050"
                        @click="onDelete()"
                    >
                    ลบ 
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        style="margin-top: -1.5rem ;" 
                        text
                        @click="onClose()"
                    >
                    ยกเลิก
                    </v-btn>
                    
                </v-col>

            </v-card>
        </div>
        </v-dialog>
    </v-row>
</template>

<script>

    
    export default {
        name : "ModalRemoveCourseMsteam",
        data: () => ({
           
        }),
        props:{
            showModal : Boolean,
            textTitle : String , 
            textDetail : String , 
            openWeb : String  
        },
        watch:{
            showModal(val){
                console.log("delete :>" , val)
                if(val){
                    this.onOpenModal()
                }
            }

        },
        created(){
         
        },
        methods:{
            onClose(){
                this.$emit('onClose')
            },
            onOpen(){
                window.open("https://www.info.tlic.cmu.ac.th/contact-us" , '_blank')
                return 0;
            },
            onDelete(){
                this.$emit('onDelete')
            },
            onOpenModal(){
                return this.showModal
            }
        },
    }
</script>

